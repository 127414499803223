import { useCallback, useMemo, useState } from "react";
import { isElsScanFileUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { assert, GUID } from "@faro-lotv/foundation";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { Typography } from "@mui/material";
import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { useFileUploadContext } from "@context-providers/file-upload/file-uploads-context";
import { useAppSelector, useAppDispatch } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { isTaskInProgress } from "@hooks/upload-tasks/upload-tasks-utils";
import { WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { summarizeRevisionChanges, wasDraftUpdated } from "@utils/capture-tree/capture-tree-changes";
import {
  cancelUploads,
  cancelRegistrationsAndDraftRevision,
} from "@pages/project-details/project-data-management/import-data/cancel-import-utils";
import { fetchCaptureTreeData, fetchRevisionsAndDraftEntities } from "@hooks/data-management/use-data-management";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { hasAnyDraftChangesSelector } from "@store/capture-tree/capture-tree-selectors";
import { useToast } from "@hooks/use-toast";
import { currentUserSelector } from "@store/user/user-selector";
import { isRegisteringSelector } from "@pages/project-details/project-data-management/registered-data/registered-data-selectors";

/** Props for Cancel Import Button */
interface Props {
  /** Workflow state of the project */
  workflowState: WorkflowState;
}

/** Button to cancel upload of scans */
export function CancelImportButton({ workflowState }: Props): JSX.Element{
  const dispatch = useAppDispatch();
  const { createDialog } = useDialog();
  const uploadTasks = useAppSelector(uploadTasksSelector);
  const { uploadManager } = useFileUploadContext();
  const cancelRevision = useCancelRevision();
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const { showToast } = useToast();

  const project = useAppSelector(selectedProjectSelector);
  assert(project, "Project has to be defined");
  const currentUser = useAppSelector(currentUserSelector);
  const projectApiClient = useProjectApiClient({ projectId: project.id });
  const progressApiClient = useProgressApiClient({ projectId: project.id.toString() });

  const isRegistering = useAppSelector(isRegisteringSelector);

  const hasAnyDraftChanges = useAppSelector(hasAnyDraftChangesSelector);

  const shouldUseUpload = workflowState === "uploading";
  // "start": If the user has deleted all scans, "Discard Draft" seems more appropriate.
  const shouldUseDiscard = workflowState === "start" ||
    workflowState === "registered" || workflowState === "registerBadResult" || workflowState === "registerError";

  /** Revision of the current Staging Area uploads in the current project, or null if no uploads are in progress. */
  const revisionIdForUpload = useMemo<GUID | null>(() => {
    for (const task of uploadTasks) {
      if (isTaskInProgress(task) && isElsScanFileUploadTaskContext(task.context) && task.context.projectId === project.id) {
        return task.context.captureTreeRevisionId;
      }
    }
    return null;
  }, [uploadTasks, project.id]);

  /** Flag to prevent multiple cancel requests. */
  const [cancelInProgress, setCancelInProgress] = useState(false);

  /**
   * Flag to disable the button depending on the workflow state.
   */
  const isButtonDisabled = useMemo<boolean>(() => {
    if (cancelInProgress) {
      return true;
    }
      return (workflowState === "uploading" && !revisionIdForUpload) ||
        (workflowState !== "uploading" && !hasAnyDraftChanges);
  }, [
    cancelInProgress, hasAnyDraftChanges, revisionIdForUpload, workflowState,
  ]);

  /**
   * Cancels the upload step and removes the tasks from the store.
   */
  const cancelUploadStep = useCallback(async (captureTreeRevisionId: GUID): Promise<void> => {
    await cancelUploads(captureTreeRevisionId, projectApiClient, cancelRevision, uploadTasks, uploadManager, trackEvent);
  }, [
    projectApiClient, cancelRevision, uploadTasks, uploadManager, trackEvent,
  ]);

  /**
   * Asks for confirmation, and cancels the running upload.
   */
  const onCancelUpload = useCallback(async (): Promise<void> => {
    if (!(await createDialog(
      {
        title: "Cancel Upload?",
        confirmText: "Cancel Upload",
        closeText: "Continue Upload",
      },
      <Typography
        sx={{
          fontSize: "14px",
          marginBottom: SPACE_ELEMENTS_OF_MODAL,
        }}
      >
        You are about to interrupt the current upload process.
        <span style={{marginTop: "8px", display: "block"}}>
          Any files that have been uploaded will be removed and you will need to start over with a new upload.
        </span>
      </Typography>
    ))) {
      return;
    }

    setCancelInProgress(true);
    try {
      assert(revisionIdForUpload, "Revision ID for upload should be defined.");
      await cancelUploadStep(revisionIdForUpload);
    } catch (error) {
      setCancelInProgress(false);
      handleErrorWithToast({
        id: `onCancelUpload-${Date.now().toString()}`,
        title: "Failed to cancel the upload process. Please try reloading the page.",
        error,
      });
    }

    // Fetch the various objects to make the UI transition back to the previous state earlier,
    // and not only when the next usePolling() happens.
    await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient)
      // Not important. The UI will update a bit later.
      .catch(() => undefined);
    setCancelInProgress(false);
  }, [cancelUploadStep, createDialog, dispatch, handleErrorWithToast, progressApiClient, projectApiClient, revisionIdForUpload]);

  /**
   * Asks for confirmation, and discards the current draft revision.
   */
  const onCancelDraft = useCallback(async (): Promise<void> => {
    async function handleOutdated(): Promise<void> {
      // Try to fetch everything again, to make sure that UI reflects latest state.
      await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient)
        .catch(() => undefined);
      showToast({
        message: "The project has been updated by another user or application. Please review the changes and try again.",
        type: "warning",
      });
    }

    /** Handle an error, returning `undefined`, to make the error handling a bit shorter. */
    function handleFetchError(error: unknown): undefined {
      handleErrorWithToast({
        id: `onCancelDraft-fetch-${Date.now().toString()}`,
        title: "Failed to fetch the latest state of the project. Please try reloading the page.",
        error,
      });
    }

    assert(currentUser, "User has to be defined");

    const updateBeforeDialog = await fetchRevisionsAndDraftEntities(dispatch, projectApiClient)
      .catch(handleFetchError);
    if (!updateBeforeDialog) {
      // We came here from `handleFetchError`, which returns undefined.
      return;
    }

    const openDraftEntities = updateBeforeDialog.openDraftEntities;
    const openDraftRevision = updateBeforeDialog.openDraftRevision;
    const changeSummary = summarizeRevisionChanges(openDraftEntities);
    if (!openDraftRevision || openDraftEntities.length === 0 || changeSummary.sum === 0) {
      await handleOutdated();
      return;
    }

    const title = shouldUseDiscard ? "Discard Draft?" : "Cancel Import?";
    const confirmText = shouldUseDiscard ? "Discard Draft" : "Cancel Import";
    const closeText = shouldUseDiscard ? "Cancel" : "Continue Import";
    const msg = shouldUseDiscard ?
      "You are about to discard the project's draft version, and revert these draft changes:" :
      "You are about to interrupt the current import process, and revert these draft changes:";

    const changes = changeSummary.textJsx.map((change, i) =>
      <li key={i}>{change}</li>
    );
    if (!(await createDialog(
      {
        title,
        confirmText,
        closeText,
      },
      <Typography
        // <ul> is not allowed below <p>.
        component={"div"}
        sx={{
          fontSize: "14px",
          marginBottom: SPACE_ELEMENTS_OF_MODAL,
        }}
      >
        {msg}
        <ul>
          {changes}
        </ul>
      </Typography>
    ))) {
      return;
    }

    const afterConfirm = await fetchRevisionsAndDraftEntities(dispatch, projectApiClient)
      .catch(handleFetchError);
    if (!afterConfirm) {
      // We came here from `handleFetchError`, which returns undefined.
      return;
    }

    if (!afterConfirm.openDraftRevision ||
      wasDraftUpdated(openDraftRevision, openDraftEntities, afterConfirm.openDraftRevision, afterConfirm.openDraftEntities)
    ) {
      await handleOutdated();
      return;
    }

    setCancelInProgress(true);
    try {
      await cancelRegistrationsAndDraftRevision(
        projectApiClient, workflowState, afterConfirm.openDraftRevision, changeSummary,
        uploadTasks, uploadManager, cancelRevision, trackEvent, currentUser, isRegistering
      );
    } catch (error) {
      setCancelInProgress(false);
      handleErrorWithToast({
        id: `onCancelDraft-${Date.now().toString()}`,
        title: "Failed to cancel the import process. Please try reloading the page.",
        error,
      });
    }

    // Fetch the various objects to make the UI transition back to the previous state earlier,
    // and not only when the next usePolling() happens.
    await fetchCaptureTreeData(dispatch, projectApiClient, progressApiClient)
      // Not important. The UI will update a bit later.
      .catch(() => undefined);
    setCancelInProgress(false);
  }, [
    cancelRevision, createDialog, dispatch, handleErrorWithToast, progressApiClient, projectApiClient, showToast,
    trackEvent, uploadManager, uploadTasks, workflowState, shouldUseDiscard, currentUser, isRegistering,
  ]);

  /** Ask for confirmation, then cancel all ongoing Staging Area uploads of the current project. */
  const onCancelImport = useCallback(async (): Promise<void> => {
    if (isButtonDisabled) {
      // Nothing to cancel; this code path should be unreachable.
      return;
    }

    if (shouldUseUpload) {
      await onCancelUpload();
    } else {
      await onCancelDraft();
    }
  }, [isButtonDisabled, onCancelDraft, onCancelUpload, shouldUseUpload]
);

  let caption: string;
  if (shouldUseDiscard) {
    caption = "Discard Draft";
  } else if (shouldUseUpload) {
    caption = "Cancel Upload";
  } else {
    caption = "Cancel Import";
  }

  let tooltip: string;
  if (isButtonDisabled) {
    tooltip = "The import cannot be canceled in the current step.";
  } else if (shouldUseDiscard) {
    tooltip = "Discard the project's draft version.";
  } else if (shouldUseUpload) {
    tooltip = "Cancel the current upload process.";
  } else {
    tooltip = "Cancel the current import process.";
  }

  return (
    <SphereTooltip
      dataTestId="sa-cancel-import-tooltip"
      // If there are multiple buttons, show them in the same row.
      boxProps={{
        sx: {
          display: "inline-block",
        },
      }}
      title={tooltip}
    >
      <FaroTextButton
        onClick={() => void onCancelImport()}
        isDisabled={isButtonDisabled}
        sx={{
          fontSize: "14px",
          fontWeight: 600,
        }}
        dataTestId="sa-cancel-import-button"
      >
        { caption }
      </FaroTextButton>
    </SphereTooltip>
  );
}
